import React, { useEffect, useState } from 'react';
import TitleToggle from 'components/globalComponents/TitleToggle';
import OrdersWrapper from 'styles/Order.style';
import { getPendingOrdersFun } from 'utils/getMethodFunction';
import DataLoader from 'components/globalComponents/Loaders/DataLoader';
import Error from 'components/globalComponents/Error';
import Empty from 'components/globalComponents/Empty';
import Card from 'Layout/Card';
import Button from 'Layout/Button';
import moment from 'moment';

const PendingOrder = () => {
  const [pendingOrders, setPendingOrders] = useState({
    list: [],
    error: null,
    process: false,
  });

  useEffect(() => {
    const callAsync = async () => {
      await getPendingOrdersFun(setPendingOrders);
    };

    callAsync();

    return () => {
      setPendingOrders({
        list: [],
        error: null,
        process: false,
      });
    };
  }, []);

  if (pendingOrders?.process) {
    return <DataLoader />;
  } else if (pendingOrders?.error) {
    return <Error status={pendingOrders?.error} />;
  }

  return (
    <>
      <TitleToggle Title="Failed Orders" />
      <div className="RightPanel">
        <OrdersWrapper>
          <div className="container Orders">
            {pendingOrders?.list?.length > 0 ? (
              <div className="row d-flex justify-content-center">
                {pendingOrders?.list.map((list) => {
                  return (
                    <div
                      className="col-lg-9 col-md-10 col-12 mt-4"
                      key={list.user_id}
                    >
                      <Card className="order_card" p="1.5rem">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="time">
                            {list?.orders?.txnDetails?.email}
                          </div>
                          <div className="time">
                            {moment(list?.orders?.date).format(
                              'MMM Do YY, h:mm a'
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 order_list">
                            <div className="row d-flex align-items-lg-center align-items-md-center align-items-start mt-4">
                              <div className="col-lg-8 col-md-8 col-sm-9 col-12 d-flex flex-row">
                                <div className="img_section">
                                  <Button
                                    active={4}
                                    p="0.2rem 0.5rem"
                                    br="4px"
                                    size="1rem"
                                    id="btn"
                                  >
                                    {list?.orders?.order_id}
                                  </Button>
                                </div>
                                <div className="book_name d-flex align-items-lg-center flex-lg-row flex-md-row flex-column pl-3">
                                  <div className="price my-lg-0 my-md-0 my-1">
                                    <span>₹{list?.orders?.txn_amount}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-8 col-sm-3 col-12">
                                <div className="price flex justify-content-end">
                                  <span>₹{list?.orders?.txn_amount}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <Empty title="Orders is empty!" />
                </div>
              </div>
            )}
          </div>
        </OrdersWrapper>
      </div>
    </>
  );
};

export default PendingOrder;
