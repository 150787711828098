import axios from 'axios';
import api from 'api';
import { getCookies } from 'utils';

// get data api section
// =========================================================
export const getAllBookFun = async (setLoading) => {
  try {
    setLoading(true);
    const response = await axios.get(api.getAllBooks);

    if (response.status === 200) {
      setLoading(false);
      return {
        books: response.data,
      };
    }
  } catch (error) {
    setLoading(false);
    if (error.response.status === 500) {
      return {
        status: error.response.status,
      };
    }
  }
};

export const getAllUpcomingBookFun = async (setLoading) => {
  try {
    setLoading(true);
    const response = await axios.get(api.getAllUpcomingBook);

    if (response.status === 200) {
      setLoading(false);
      return {
        books: response.data,
      };
    }
  } catch (error) {
    setLoading(false);
    if (error.response.status === 500) {
      return {
        status: error.response.status,
      };
    }
  }
};

export const getAllEventFun = async (setLoading) => {
  try {
    setLoading(true);
    const response = await axios.get(api.getAllEvent);

    if (response.status === 200) {
      setLoading(false);
      return {
        events: response.data,
      };
    }
  } catch (error) {
    setLoading(false);
    if (error.response.status === 500) {
      return {
        status: error.response.status,
      };
    }
  }
};

export const getAllPosterFun = async (setLoading) => {
  try {
    setLoading(true);
    const response = await axios.get(api.getAllPoster);

    if (response.status === 200) {
      setLoading(false);
      return {
        poster: response.data,
      };
    }
  } catch (error) {
    setLoading(false);
    if (error.response.status === 500) {
      return {
        status: error.response.status,
      };
    }
  }
};

export const getAllPinCodeFun = async (setLoading) => {
  try {
    setLoading(true);

    const Token = getCookies();

    const response = await axios.get(api.getAllPinCode, {
      headers: {
        Authorization: Token,
      },
    });

    if (response.status === 200) {
      setLoading(false);
      return {
        PinCodes: response.data,
      };
    }
  } catch (error) {
    setLoading(false);
    if (error.response.status === 500) {
      return {
        status: error.response.status,
      };
    }
  }
};

// get all orders
export const getCurrentOrdersFun = async (setCurrentOrders) => {
  try {
    setCurrentOrders({
      process: true,
    });

    const Token = getCookies();

    const response = await axios.get(api.getCurrentOrders, {
      headers: {
        Authorization: Token,
      },
    });

    if (response.status === 200) {
      setCurrentOrders({
        list: response.data,
        error: null,
        process: false,
      });
    }
  } catch (error) {
    setCurrentOrders({
      list: [],
      error: error.response.status,
      process: false,
    });
  }
};

export const getPastOrdersFun = async (setPastOrders) => {
  try {
    setPastOrders({
      process: true,
    });

    const Token = getCookies();

    const response = await axios.get(api.getPastOrders, {
      headers: {
        Authorization: Token,
      },
    });

    if (response.status === 200) {
      setPastOrders({
        list: response.data,
        error: null,
        process: false,
      });
    }
  } catch (error) {
    setPastOrders({
      list: [],
      error: error.response.status,
      process: false,
    });
  }
};

// get pending orders
export const getPendingOrdersFun = async (setPendingOrders) => {
  try {
    setPendingOrders({
      process: true,
    });

    const Token = getCookies();

    const response = await axios.get(api.getPendingOrders, {
      headers: {
        Authorization: Token,
      },
    });

    if (response.status === 200) {
      setPendingOrders({
        list: response.data,
        error: null,
        process: false,
      });
    }
  } catch (error) {
    setPendingOrders({
      list: [],
      error: error.response.status,
      process: false,
    });
  }
};
