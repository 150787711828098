import styled from 'styled-components';

const OrdersWrapper = styled.div`
  .Orders {
    .order_card {
      .time {
        font-size: 0.9rem;
      }

      .order_list {
        .img_section {
          img {
            width: auto;
            height: 4rem;
          }
        }

        .book_name {
          span {
            overflow: hidden;
            width: auto;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-size: 0.95rem;
          }
          .price {
            display: none;
          }
        }

        .price {
          display: flex;
          span {
            color: ${(props) => props.theme.BodyPC_Main};
            font-weight: 500;
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 766px) {
    .Orders {
      .order_card {
        padding: 0.5rem 0.8rem 1rem 0.8rem;

        .order_list {
          .book_name {
            .price {
              display: flex;
            }
            .status {
              display: flex;
            }
          }

          .price {
            display: none;
          }
        }
      }
    }
  }
`;

export default OrdersWrapper;
