import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleToggle from 'components/globalComponents/TitleToggle';
import OrdersWrapper from 'styles/Order.style';
import { getCurrentOrdersFun } from 'utils/getMethodFunction';
import DataLoader from 'components/globalComponents/Loaders/DataLoader';
import Error from 'components/globalComponents/Error';
import Empty from 'components/globalComponents/Empty';
import Card from 'Layout/Card';
import Button from 'Layout/Button';
import moment from 'moment';

const CurrentOrders = () => {
  const navigate = useNavigate();
  const [currentOrders, setCurrentOrders] = useState({
    list: [],
    error: null,
    process: false,
  });

  useEffect(() => {
    const callAsync = async () => {
      await getCurrentOrdersFun(setCurrentOrders);
    };

    callAsync();

    return () => {
      setCurrentOrders({
        list: [],
        error: null,
        process: false,
      });
    };
  }, []);

  if (currentOrders?.process) {
    return <DataLoader />;
  } else if (currentOrders?.error) {
    return <Error status={currentOrders?.error} />;
  }

  return (
    <>
      <TitleToggle Title="Current Orders" />
      <div className="RightPanel">
        <OrdersWrapper>
          <div className="container Orders">
            {currentOrders?.list?.length > 0 ? (
              <div className="row d-flex justify-content-center">
                {currentOrders?.list.map((list) => {
                  return (
                    <div
                      className="col-lg-9 col-md-10 col-12 mt-4"
                      key={list.user_id}
                    >
                      <Card className="order_card" p="1.5rem">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="time">
                            {list?.orders?.txnDetails?.email}
                          </div>
                          <div className="time">
                            {moment(list?.orders?.date).format(
                              'MMM Do YY, h:mm a'
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 order_list">
                            <div className="row d-flex align-items-lg-center align-items-md-center align-items-start mt-4">
                              <div className="col-lg-8 col-md-6 col-sm-6 col-12 d-flex flex-row">
                                <div className="img_section">
                                  <Button
                                    active={4}
                                    p="0.2rem 0.5rem"
                                    br="4px"
                                    size="1rem"
                                    id="btn"
                                  >
                                    {list?.orders?.order_id}
                                  </Button>
                                </div>
                                <div className="book_name d-flex align-items-lg-center flex-lg-row flex-md-row flex-column pl-3">
                                  <div className="price my-lg-0 my-md-0 my-1">
                                    <span>₹{list?.orders?.txn_amount}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-4 col-sm-2 col-12">
                                <div className="price">
                                  <span>₹{list?.orders?.txn_amount}</span>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-2 col-12 d-flex justify-content-start justify-content-lg-end">
                                <div className="details_btn mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                  <Button
                                    active={4}
                                    p="0.2rem 0.5rem"
                                    br="4px"
                                    size="1rem"
                                    id="btn"
                                    onClick={() =>
                                      navigate(
                                        `/orders/details?userId=${list.user_id}&orderId=${list?.orders?._id}`
                                      )
                                    }
                                  >
                                    Details
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <Empty title="Orders is empty!" />
                </div>
              </div>
            )}
          </div>
        </OrdersWrapper>
      </div>
    </>
  );
};

export default CurrentOrders;
